import React from 'react'
import Layout from '../components/layout'
import Title from 'components/partials/title'
import { Box } from '@chakra-ui/react'
import Meta from 'components/meta'

const Faq = () => {
  return (
    <Layout>
      <Meta title="FAQ" />
      <Title text={'FAQ'} desc={'We are adding details soon..'} titleAlign={'center'} />
      <Box h={'75vh'} />
    </Layout>
  )
}

export default Faq
