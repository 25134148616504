import { extendTheme } from '@chakra-ui/react'
import '@fontsource/ibm-plex-serif'
import '@fontsource/josefin-sans'

const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1200px',
}

const fonts = {
  heading: `'Josefin Sans', sans-serif`,
  body: `'IBM Plex Serif', sans-serif`,
}

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const customTheme = extendTheme({ breakpoints, fonts, config })

export default customTheme
