import React from 'react'
import Header, { HeaderBanner } from './header'
import { Flex, css } from '@chakra-ui/react'
import Footer from './footer'
import ScrollToAnchor from './scrolltoanchor'

const Layout = (props) => {
  const { children } = props

  return (
    <Flex direction={'column'}>
      <Section
        className={'navbar'}
        full
        sectionProps={{ bg: 'green.700' }}
        css={css({
          '@media print': {
            display: 'none',
          },
        })}
      >
        <Flex className={'container'} as={'header'} alignItems={'stretch'}>
          <HeaderBanner />
        </Flex>
      </Section>
      <Section
        className={'navbar'}
        full
        sectionProps={{ bg: 'white' }}
        css={css({
          '@media print': {
            position: 'relative!important',
            top: 'auto!important',
          },
        })}
      >
        <Flex
          className={'container'}
          as={'header'}
          alignItems={'stretch'}
          sx={{ background: 'var(--chakra-colors-chakra-body-bg)' }}
        >
          <Header />
        </Flex>
      </Section>
      <ScrollToAnchor />
      <Flex as={'main'} id="main-content" direction={'column'}>
        {children}
      </Flex>
      <Flex className={'container navbar-container'} alignItems={'stretch'} as={'footer'}>
        <Footer />
      </Flex>
    </Flex>
  )
}

export const Section = (props) => {
  const { children, full = false, sectionProps = {}, className = '', id = '', ...rest } = props

  if (!full) {
    return (
      <Flex
        id={id}
        as={'section'}
        className={`container ${className}`}
        direction={'column'}
        {...rest}
      >
        {children}
      </Flex>
    )
  }
  return (
    <Flex
      id={id}
      as={'section'}
      className={`section ${className}`}
      {...sectionProps}
      direction={'column'}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default Layout
