import React from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import { Box, Heading, Text, List, ListItem, Link, UnorderedList, Code } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import Meta from 'components/meta'

const Returns = () => {
  return (
    <Layout>
      <Section>
        <Meta title="Returns & Refunds" />
        <Title
          id="shipping-policy"
          text={'Shipping Policy'}
          titleAlign={'center'}
          stickyHeader={true}
        />
        <Box h={'75vh'} flex={1} w={'100%'} alignItems={'flex-start'}>
          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Commitment to quality service
          </Heading>
          <Text mb={4}>
            We pride ourselves on providing quality service to our valued customers. All shipments
            are entrusted to reputable courier services to ensure the best & safe delivery
            experience.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Domestic & international transit times
          </Heading>
          <UnorderedList mb={4} listStyleType={'none'}>
            <ListItem>
              <ArrowForwardIcon color={'green.700'} /> Domestic Shipments: Typically in transit for{' '}
              <Text as={'strong'}>3 - 7 days</Text>.
            </ListItem>
            <ListItem>
              <ArrowForwardIcon color={'green.700'} /> International Shipments: Typically in transit
              for <Text as={'strong'}>4 - 22 days</Text>.
            </ListItem>
          </UnorderedList>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Dispatch and operation hours
          </Heading>
          <Text mb={4}>
            Orders are dispatched within <Text as={'strong'}>2 business days</Text> following
            payment confirmation. Our stores operate <Text as={'strong'}>Monday - Saturday</Text>{' '}
            during standard business hours, excluding national and local state holidays.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Tracking your order
          </Heading>
          <Text mb={4}>
            Upon dispatch, customers will receive a tracking ID/link, enabling them to monitor their
            shipment's progress via updates from the shipping provider.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Cancellations
          </Heading>
          <Text mb={4}>
            Cancellations are accepted any time before the order has been dispatched. If you wish to
            cancel an order that has already been dispatched, please refer to our{' '}
            <Link as={ReactRouterLink} to="#return-policy" color={'green.700'}>
              Return Policy
            </Link>
            .
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Stock availability
          </Heading>
          <Text mb={4}>
            We endeavor to maintain accurate stock counts on our website. However, should a stock
            discrepancy occur, we will fulfill available products and inform you regarding the
            option to await restocking or process a refund.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Additional charges
          </Heading>
          <UnorderedList mb={4} listStyleType={'none'}>
            <ListItem>
              <ArrowForwardIcon color={'green.700'} /> Cash on Delivery (COD) is not available.
            </ListItem>
            <ListItem>
              <ArrowForwardIcon color={'green.700'} /> Customers are responsible for any additional
              tax or octroi charges.
            </ListItem>
          </UnorderedList>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Delivery information
          </Heading>
          <Text mb={4}>
            All orders are delivered to the address provided at the time of order placement. Please
            ensure shipping information is complete and accurate.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Lost parcels
          </Heading>
          <Text mb={4}>
            In the event of a lost parcel, we will process a refund or replacement following an
            investigation by the courier service.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Customer service
          </Heading>
          <Text mb={4}>
            For inquiries, bulk orders, or international shipping requests, please contact us{' '}
            <Link as={ReactRouterLink} to="/articles#bulk-order" color={'green.700'}>
              here
            </Link>{' '}
            (or) at{' '}
            <Link href="mailto:ecocraftsbbsr@gmail.com" isExternal color={'green.700'}>
              ecocraftsbbsr@gmail.com
            </Link>
            .
          </Text>

          <Title
            id="return-policy"
            text={'Return Policy'}
            desc={'Within India'}
            titleAlign={'center'}
          />
          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Return conditions
          </Heading>
          <UnorderedList mb={4} listStyleType={'none'}>
            <ListItem>
              <ArrowForwardIcon color={'green.700'} /> Eligible products may be returned within{' '}
              <Text as={'strong'}>7 days</Text> of delivery.
            </ListItem>
            <ListItem>
              <ArrowForwardIcon color={'green.700'} /> Returns must be in original packaging, unused
              and in resellable condition.
            </ListItem>
          </UnorderedList>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Eligibility
          </Heading>
          <Text mb={4}>
            Return eligibility is indicated on the product page. Please review this information
            before placing your order.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Damaged products
          </Heading>
          <Text mb={4}>
            Report damaged products within <Text as={'strong'}>24 hours</Text> of receipt by
            emailing{' '}
            <Link href="mailto:ecocraftsbbsr@gmail.com" isExternal color={'green.700'}>
              ecocraftsbbsr@gmail.com
            </Link>{' '}
            with a photo and the Order Number.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Return approval
          </Heading>
          <Text mb={4}>
            Return requests must be approved by the ecocrafts team and are subject to our
            discretion.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Stock availability and credit notes
          </Heading>
          <Text mb={4}>
            If the returned item is in stock, it will be replaced. If out of stock, a credit note
            valid for <Text as={'strong'}>12 months</Text> will be issued within{' '}
            <Text as={'strong'}>7 days</Text> of receipt.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Refunds
          </Heading>
          <Text mb={4}>
            Credit notes cover the value of the returned goods but do not include shipping costs and
            bank charges.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Product variations
          </Heading>
          <Text mb={4}>
            Due to monitor calibration differences and handloom variations, slight color
            discrepancies may occur. Such variations are not grounds for return or exchange.
          </Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Non-Returnable items
          </Heading>
          <Text mb={4}>Beauty & personalized items are not eligible for return or exchange.</Text>

          <Heading mt={'30px'} as={'h4'} size={'md'}>
            Further assistance
          </Heading>
          <Text mb={4}>
            For further assistance or concerns regarding returns, please reach out to us at{' '}
            <Link href="mailto:ecocraftsbbsr@gmail.com" isExternal color={'green.700'}>
              ecocraftsbbsr@gmail.com
            </Link>
            .
          </Text>
        </Box>
      </Section>
    </Layout>
  )
}

export default Returns
