import React, { useEffect, useState } from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import { Box, Flex, HStack, SimpleGrid, Stack, VStack, useBoolean, css } from '@chakra-ui/react'
import ArticleService from 'service/articles.service'
import ArticleCard from 'components/partials/articlecard'
import Meta from 'components/meta'
import BulkOrder from 'components/partials/bulkorder'

// https://stackoverflow.com/a/61129057

const Articles = () => {
  const [loading, setLoading] = useBoolean(true)
  const [articles, setArticles] = useState([])

  useEffect(() => {
    ArticleService.all().then((data) => {
      setArticles(data)
      setLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Section id="articles">
        <Meta title="Articles" />
        <Title
          text={'Articles'}
          // desc={
          //   <HStack w={'100%'} alignItems={'flex-start'} justifyContent={'flex-start'}>
          //     <Text mb={0}>Category:</Text>
          //     <Text mb={0}>crafts</Text>
          //   </HStack>
          // }
        />
        <Flex alignItems={'center'} w={'100%'} direction={'column'} minHeight={'85vh'}>
          <SimpleGrid
            w={'100%'}
            gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
            mb={{ sm: '20px', md: '40px', lg: '60px' }}
            gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridTemplateColumns={{ sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr' }}
            gridAutoRows={'1fr'}
            gridTemplateRows={'auto auto'}
            alignContent={'space-between'}
            css={css({
              '@media print': {
                gridTemplateColumns: '1fr 1fr',
                gridColumnGap: '60px',
                gridRowGap: '60px',
              },
            })}
          >
            {loading ? (
              <>
                {Array(5)
                  .fill('')
                  .map((key, index) => (
                    <Stack
                      w={'100%'}
                      key={`key-${index}`}
                      alignItems={'flex-start'}
                      direction={{ sm: 'column', md: 'column', lg: 'row' }}
                      mb={{ sm: '90px', md: '20px', lg: 0 }}
                    >
                      <Box bg="#f3f3f3" h={{ sm: '200px', md: '240px', lg: '280px' }} w={'100%'} />
                      <VStack w={'100%'} direction={'column'} alignItems={'flex-start'}>
                        <HStack mb={'10px'}>
                          <Box bg="#f3f3f3" h={'10px'} w={'100px'} />
                        </HStack>
                        <Box bg="#f3f3f3" h={{ sm: '20px', md: '30px', lg: '30px' }} w={'100%'} />
                        <Box
                          bg="#f3f3f3"
                          h={{ sm: '20px', md: '30px', lg: '30px' }}
                          mb={'30px'}
                          w={'80%'}
                        />
                        <Box bg="#f3f3f3" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'100%'} />
                        <Box bg="#f3f3f3" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'100%'} />
                        <Box bg="#f3f3f3" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'100%'} />
                        <Box bg="#f3f3f3" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'60%'} />
                      </VStack>
                    </Stack>
                  ))}
              </>
            ) : (
              <>
                {articles.map((article, index) => (
                  <ArticleCard article={article} key={`article-${article.id}-${index}`} />
                ))}
              </>
            )}
          </SimpleGrid>
        </Flex>
      </Section>
      <BulkOrder />
    </Layout>
  )
}

export default Articles
