import React, { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Flex, Img, VStack, Text, Heading, Stack, HStack, useBoolean } from '@chakra-ui/react'
import defaultcard from 'styles/assets/adefaultcard.png'
import { Timestamp } from 'firebase/firestore'

const ArticleCard = ({ article }) => {
  const [loading, setLoading] = useBoolean(true)
  const { title, thumbnail, created } = article.data

  const handleLoad = () => {
    setTimeout(() => {
      setLoading.off()
    }, 1000)
  }
  const timeStamp = new Timestamp(created.seconds, created.nanoseconds).toDate()
  const createdAt = new Date(timeStamp).toLocaleDateString()

  return (
    <Stack
      title={title}
      w={'100%'}
      alignItems={'flex-start'}
      direction={{ sm: 'column', md: 'column', lg: 'row' }}
      mb={{ sm: '90px', md: '20px', lg: 0 }}
    >
      <Flex
        w={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        overflow={'hidden'}
        position={'relative'}
        as={ReactRouterLink}
        to={`/article/${article.id}`}
      >
        <Img
          src={thumbnail}
          maxHeight={'100%'}
          zIndex={1}
          verticalAlign={'middle'}
          display={!loading ? 'inline-block' : 'none'}
          _hover={{ transform: 'scale(3)', transition: 'transform 0.6s' }}
          onLoad={handleLoad}
        />
        {loading && (
          <Img src={defaultcard} maxHeight={'100%'} zIndex={0} display={'inline-block'} />
        )}
      </Flex>
      <VStack w={'100%'} direction={'column'} alignItems={'flex-start'}>
        <HStack mb={'10px'} w={'100%'} alignItems={'center'} justifyContent={'flex-start'}>
          <Text as={'small'} mb={0}>
            {createdAt}
          </Text>
        </HStack>
        <Heading as={'h4'} mb={'10px'} fontSize={{ sm: '20px', md: '30px' }} fontWeight={200}>
          <Text as={ReactRouterLink} to={`/article/${article.id}`}>
            {title}
          </Text>
        </Heading>
      </VStack>
    </Stack>
  )
}

export default ArticleCard
