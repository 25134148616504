import React, { useEffect, useState } from 'react'
import Layout, { Section } from '../components/layout'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Spinner,
  Img,
  Heading,
  Text,
  Button,
  ButtonGroup,
  Divider,
  ListItem,
  List,
  ListIcon,
  useBoolean,
  SimpleGrid,
  css,
  VStack,
} from '@chakra-ui/react'
import ProductService from 'service/products.service'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ArrowForwardIcon, ChevronRightIcon, StarIcon } from '@chakra-ui/icons'
import amazon from 'styles/assets/amazon.png'
import whatsapp from 'styles/assets/whatsapp.png'
import mixed from 'styles/assets/mixed.png'
import { static_assets_url } from '../firebase'
import Meta from 'components/meta'
import ProductCard from 'components/partials/productcard'

const Product = () => {
  const { productid } = useParams()
  const [loading, setLoading] = useBoolean(true)
  const [rloading, setRLoading] = useBoolean(true)
  const [product, setProduct] = useState(null)
  const [related, setRelated] = useState([])
  const [category, setCategory] = useState(null)
  const [toggleMore, setToggleMore] = useBoolean()

  useEffect(() => {
    ProductService.get(productid).then((data) => {
      setProduct(data.details)
      setCategory(data.category)
      setRelated([])
      setLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productid])

  useEffect(() => {
    if (product?.related) {
      setRLoading.on()
      ProductService.getRelated(product.related).then((data) => {
        setRelated(data)
        setRLoading.off()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  if (loading) {
    return (
      <Layout>
        <Flex h={'75vh'} w={'100%'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      </Layout>
    )
  }

  const ProductDetails = ({ name, price, link, display, align, rating, ...rest }) => (
    <Box
      px={'30px'}
      w={'100%'}
      maxWidth={{ lg: '490px', sm: '100%' }}
      display={display}
      flexDir={'column'}
      alignItems={align}
      {...rest}
    >
      <Heading
        as={'h1'}
        size={{ sm: '2xl', md: '4xl' }}
        fontWeight={'300'}
        mb={'30px'}
        property="schema:name"
        content={name}
      >
        {name}
      </Heading>
      <Box my={'30px'} rel="schema:offers">
        {price?.onwards ? (
          <Text fontSize={'3xl'} as={'b'} property="schema:lowPrice" content={price.value}>
            &#8377;{price.value} INR onwards
            <Flex as={'span'} property="schema:priceCurrency" content="INR" />
            <Flex as={'span'} property="schema:price" content={price.value} />
          </Text>
        ) : (
          <>
            <Text fontSize={'3xl'} as={'b'} property="schema:lowPrice" content={price.value}>
              &#8377;{price.value} INR
            </Text>
            <Text
              fontSize={'xl'}
              as={'s'}
              ml={'20px'}
              property="schema:highPrice"
              content={price.actual}
            >
              &#8377;{price.actual} INR
            </Text>
            <Flex as={'span'} property="schema:priceCurrency" content="INR" />
            <Flex as={'span'} property="schema:price" content={price.value} />
          </>
        )}
        <Text fontSize={'l'} as={'i'} mt={'20px'} color={'gray.500'}>
          Includes Tax
        </Text>
        <Flex as={'span'} property="schema:availability" content={'InStock'} />
        {rating > 0 && (
          <Flex
            property="schema:rating"
            content={rating}
            title={`Rating: ${rating} ★`}
            height={'36px'}
            alignItems={'center'}
            mt={'20px'}
          >
            {Array.from(new Array(rating)).map((item) => (
              <StarIcon key={item} color={'green.700'} mr={'4px'} boxSize={'6'} />
            ))}
            {rating < 5 &&
              Array.from(new Array(5 - rating)).map((item) => (
                <StarIcon key={item} color={'gray.300'} mr={'4px'} boxSize={'6'} />
              ))}
          </Flex>
        )}
      </Box>
      <ButtonGroup isAttached w={'80%'} h={'68px'}>
        {link.type === 'whatsapp' && (
          <>
            <Button
              p={8}
              borderWidth={2}
              borderColor={'black'}
              bg={'black'}
              color={'white'}
              borderRadius={0}
              fontWeight={300}
              alignItems={'center'}
              _hover={{ background: 'white', color: 'black' }}
              _focus={{ background: 'white', color: 'black' }}
              flexGrow={1}
              as={'a'}
              target={'_blank'}
              href={link.to.replace(
                '{placeholder}',
                encodeURIComponent(name + ', ' + document.location.href),
              )}
              rel="schema:url"
              resource={link.to.replace(
                '{placeholder}',
                encodeURIComponent(name + ', ' + document.location.href),
              )}
            >
              Contact to Buy
            </Button>
            <Flex
              bg={'#25d366'}
              borderRightWidth={2}
              borderTopWidth={2}
              borderBottomWidth={2}
              borderColor={'#25d366'}
              borderRadius={0}
              h={'68px'}
              w={'68px'}
              alignItems={'center'}
              my={'auto'}
              justifyContent={'center'}
              aria-label="Buy at amazon"
              _hover={{ background: '#25d366', color: 'black' }}
              _focus={{ background: '#25d366', color: 'black' }}
            >
              <Img src={whatsapp} w={'40px'} h={'40px'} />
            </Flex>
          </>
        )}
        {link.type === 'amazon' && (
          <>
            <Button
              p={8}
              borderWidth={2}
              borderColor={'black'}
              bg={'black'}
              color={'white'}
              borderRadius={0}
              fontWeight={300}
              alignItems={'center'}
              _hover={{ background: 'white', color: 'black' }}
              _focus={{ background: 'white', color: 'black' }}
              flexGrow={1}
              as={'a'}
              target={'_blank'}
              href={link.to}
              rel="schema:url"
              resource={link.to}
            >
              Buy at Amazon
            </Button>
            <Flex
              bg={'#febd69'}
              borderRightWidth={2}
              borderTopWidth={2}
              borderBottomWidth={2}
              borderColor={'#febd69'}
              borderRadius={0}
              h={'68px'}
              w={'68px'}
              alignItems={'center'}
              my={'auto'}
              justifyContent={'center'}
              aria-label="Buy at amazon"
              _hover={{ background: '#febd69', color: 'black' }}
              _focus={{ background: '#febd69', color: 'black' }}
            >
              <Img src={amazon} w={'40px'} h={'40px'} mt={'6px'} />
            </Flex>
          </>
        )}
      </ButtonGroup>
    </Box>
  )

  const {
    name,
    images,
    price,
    link,
    description,
    about,
    sku,
    variant,
    details: { material, color, origin, pack },
    caption,
    dimension,
    meta,
    slug,
    rating = 0,
  } = product

  return (
    <Layout>
      <Section>
        <Meta
          title={meta.title}
          description={meta.description}
          name={meta.title}
          image={images[0]}
        />
        <Box w={'100%'} py={{ sm: '15px', lg: '30px' }}>
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color={'black'} />}
            justifyContent={{ sm: 'center', lg: 'flex-start' }}
            display={'flex'}
            vocab="https://schema.org/"
            typeof="BreadcrumbList"
          >
            <BreadcrumbItem property="itemListElement" typeof="ListItem">
              <BreadcrumbLink as={Link} to="/products" property="item" typeof="WebPage">
                <Text as={'span'} property="name">
                  Shop
                </Text>
              </BreadcrumbLink>
              <meta property="position" content="1" />
            </BreadcrumbItem>
            <BreadcrumbItem property="itemListElement" typeof="ListItem">
              <BreadcrumbLink
                as={Link}
                to={`/products/${category}`}
                textTransform={'capitalize'}
                property="item"
                typeof="WebPage"
              >
                <Text as={'span'} property="name">
                  {category}
                </Text>
              </BreadcrumbLink>
              <meta property="position" content="2" />
            </BreadcrumbItem>
            <BreadcrumbItem property="itemListElement" typeof="ListItem" isCurrentPage>
              <BreadcrumbLink
                as={Link}
                to={`/product/${slug}`}
                textTransform={'capitalize'}
                property="item"
                typeof="WebPage"
              >
                <Text as={'span'} property="name">
                  {name}
                </Text>
              </BreadcrumbLink>
              <meta property="position" content="3" />
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
        <Flex typeof="schema:Product" w={'100%'} flexWrap={'wrap'}>
          <Flex
            w={'100%'}
            flexWrap={'wrap'}
            alignItems={'flex-start'}
            flexDir={{ sm: 'column', lg: 'row' }}
          >
            <Flex flex={0.5} w={'100%'} flexDir={'column'}>
              <ProductDetails
                name={name}
                price={price}
                link={link}
                rating={rating}
                display={{ sm: 'flex', lg: 'none' }}
                align={'center'}
                pt={'30px'}
                pb={'60px'}
              />
              {Array(2)
                .fill(images[0])
                .map((image, index) => (
                  <Img
                    key={`image-${index}`}
                    mb={'30px'}
                    src={image}
                    alt={name}
                    rel="schema:image"
                    resource={image}
                  />
                ))}
              <Img
                src={`${static_assets_url}/site/ecocrafts_brand.png`}
                alt={'ecocrafts'}
                mb={'30px'}
                rel="schema:image"
                resource={`${static_assets_url}/site/ecocrafts_brand.png`}
              />
            </Flex>
            <Flex
              flex={0.5}
              w={'100%'}
              position={{ lg: 'sticky' }}
              top={'130px'}
              flexDir={'column'}
              alignItems={'center'}
            >
              <ProductDetails
                name={name}
                price={price}
                link={link}
                rating={rating}
                display={{ sm: 'none', lg: 'flex' }}
                align={'flex-start'}
                pb={'30px'}
              />
              <Box
                px={{ lg: '30px', sm: 0 }}
                w={'100%'}
                flexDir={'column'}
                maxWidth={{ lg: '490px', sm: '100%' }}
                mb={'90px'}
              >
                <Text
                  noOfLines={toggleMore ? 1000 : 6}
                  property="schema:description"
                  content={description}
                >
                  {description}
                </Text>
                {!toggleMore && (
                  <Text
                    display={'block'}
                    as={'a'}
                    cursor={'pointer'}
                    textAlign={'right'}
                    onClick={setToggleMore.on}
                    color={'green.700'}
                  >
                    ... more
                  </Text>
                )}
                <List my={'30px'}>
                  {variant && (
                    <ListItem borderTopWidth={1}>
                      <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                        <Text as={'h3'} color={'gray.500'}>
                          Variant
                        </Text>
                        <Text as={'h3'} property="schema:variant" content={variant}>
                          {variant}
                        </Text>
                      </Flex>
                    </ListItem>
                  )}
                  <ListItem borderTopWidth={1}>
                    <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                      <Text as={'h3'} color={'gray.500'}>
                        Material
                      </Text>
                      <Text as={'h3'} property="schema:material" content={material}>
                        {material}
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem borderTopWidth={1} borderBottomWidth={1}>
                    <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                      <Text as={'h3'} color={'gray.500'}>
                        Color
                      </Text>
                      {Array.isArray(color) ? (
                        <Flex>
                          {color.map((code, idx) => {
                            return code.toLowerCase() === 'mixed' ? (
                              <Img
                                key={`code-${idx}`}
                                title={code}
                                src={mixed}
                                h={6}
                                w={6}
                                ml={'8px'}
                                property="schema:color"
                                content={code}
                              />
                            ) : (
                              <Box
                                key={`code-${idx}`}
                                title={code}
                                h={6}
                                w={6}
                                bg={code}
                                ml={'8px'}
                                property="schema:color"
                                content={code}
                              />
                            )
                          })}
                        </Flex>
                      ) : (
                        <Box
                          title={color}
                          h={6}
                          w={6}
                          borderWidth={1}
                          bg={color}
                          property="schema:color"
                          content={color}
                        />
                      )}
                    </Flex>
                  </ListItem>
                </List>
              </Box>
            </Flex>
          </Flex>
          <Divider my={{ sm: '15px', lg: '30px' }} />
          <Box w={'100%'} overflow={'hidden'}>
            <Heading as={'h4'} fontSize={'2xl'} property="schema:caption" content={caption}>
              {caption}
            </Heading>
            <List spacing={3} mt={'30px'} property="schema:details" content={about.join(', ')}>
              {about.map((text, idx) => (
                <ListItem key={`about-${idx}`}>
                  <ListIcon as={ArrowForwardIcon} color="green.700" />
                  <Text as={'label'}>{text}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box w={'100%'} overflow={'hidden'} mt={'60px'}>
            <Heading as={'h4'} fontSize={'2xl'}>
              Details
            </Heading>
            <List spacing={3} my={'30px'} width={{ sm: '100%', lg: '50%' }}>
              <ListItem borderTopWidth={1}>
                <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                  <Text as={'h3'} color={'gray.500'}>
                    SKU
                  </Text>
                  <Text as={'code'} fontSize={'small'} property="schema:sku" content={sku}>
                    {sku}
                  </Text>
                </Flex>
              </ListItem>
              <ListItem borderTopWidth={1}>
                <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                  <Text as={'h3'} color={'gray.500'}>
                    Origin
                  </Text>
                  <Text as={'h3'} property="schema:origin" content={origin}>
                    {origin}
                  </Text>
                </Flex>
              </ListItem>
              <ListItem borderTopWidth={1}>
                <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                  <Text as={'h3'} color={'gray.500'}>
                    Dimension (W x H x L)
                  </Text>
                  <Text
                    as={'code'}
                    fontSize={'small'}
                    property="schema:dimension"
                    content={`${dimension.width} x ${dimension.height} x ${dimension.length}`}
                  >
                    {dimension.width} x {dimension.height} x {dimension.length}
                  </Text>
                </Flex>
              </ListItem>
              {pack && (
                <ListItem borderTopWidth={1}>
                  <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                    <Text as={'h3'} color={'gray.500'}>
                      Pack of
                    </Text>
                    <Text as={'code'} fontSize={'small'}>
                      {pack}
                    </Text>
                  </Flex>
                </ListItem>
              )}
              <ListItem borderTopWidth={1} borderBottomWidth={1}>
                <Flex flexDir={'row'} justifyContent={'space-between'} flex={1} py={3}>
                  <Text as={'h3'} color={'gray.500'}>
                    Weight
                  </Text>
                  <Text
                    as={'code'}
                    fontSize={'small'}
                    property="schema:weight"
                    content={dimension.weight}
                  >
                    {dimension.weight}
                  </Text>
                </Flex>
              </ListItem>
            </List>
          </Box>
        </Flex>
        {product.related && product.related.length > 0 && (
          <Box w={'100%'} overflow={'hidden'} mt={'60px'}>
            <Heading as={'h3'} fontSize={'3xl'}>
              Also Consider
            </Heading>
            <Flex
              id="related-products"
              alignItems={'center'}
              w={'100%'}
              direction={'column'}
              mt={'30px'}
            >
              <SimpleGrid
                w={'100%'}
                gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
                mb={{ sm: '20px', md: '40px', lg: '60px' }}
                gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
                gridTemplateColumns={{ sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
                gridAutoRows={'1fr'}
                gridTemplateRows={'auto auto'}
                alignContent={'space-between'}
                css={css({
                  '@media print': {
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridColumnGap: '60px',
                    gridRowGap: '60px',
                  },
                })}
              >
                {rloading ? (
                  <>
                    {Array(3)
                      .fill('')
                      .map((key, index) => (
                        <VStack key={`key-${index}`} w={'100%'} alignItems={'flex-start'}>
                          <Box
                            bg="#f3f3f3"
                            h={{ sm: '200px', md: '240px', lg: '360px' }}
                            w={'100%'}
                          />
                          <Box bg="#f3f3f3" h={{ sm: '20px', md: '30px', lg: '30px' }} w={'60%'} />
                          <Box bg="#f3f3f3" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'30%'} />
                        </VStack>
                      ))}
                  </>
                ) : (
                  <>
                    {related.map((product, index) => (
                      <ProductCard product={product} key={`product-${product.id}-${index}`} />
                    ))}
                  </>
                )}
              </SimpleGrid>
            </Flex>
          </Box>
        )}
      </Section>
    </Layout>
  )
}

export default Product
