import React from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import { Box, Text, Heading, Link } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import Meta from 'components/meta'

const Privacy = () => {
  return (
    <Layout>
      <Section>
        <Meta title="Privacy" description="Privacy Policy" />
        <Title stickyHeader={true} text={'Privacy Policy'} titleAlign={'center'} />
        <Box h={'75vh'} flex={1} w={'100%'} alignItems={'flex-start'}>
          <Text mx={0}>
            At ecocrafts, accessible from ecocrafts.in, one of our main priorities is the privacy of
            our visitors. This Privacy Policy document contains types of information that is
            collected and recorded by ecocrafts and how we use it.
          </Text>
          <Text mx={0}>
            If you have additional questions or require more information about our Privacy Policy,
            do not hesitate to{' '}
            <Text
              as={ReactRouterLink}
              color={'green.700'}
              textDecoration={'underline'}
              fontWeight={'600'}
              to={'/contact'}
            >
              contact
            </Text>{' '}
            us.
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Log Files
          </Heading>
          <Text mx={0}>
            ecocrafts follows a standard procedure of using log files. These files log visitors when
            they visit websites. All hosting companies do this and a part of hosting services'
            analytics. The information collected by log files include internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are not linked to any
            information that is personally identifiable. The purpose of the information is for
            analyzing trends, administering the site, tracking users' movement on the website, and
            gathering demographic information. Our Privacy Policy was created with the help of the{' '}
            <Link
              target={'_blank'}
              color={'green.700'}
              textDecoration={'underline'}
              fontWeight={'600'}
              href="https://www.webterms.org/privacy-policy-generator/"
            >
              Privacy Policy Generator
            </Link>
            .
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Cookies and Web Beacons
          </Heading>
          <Text mx={0}>
            Like any other website, ecocrafts uses "cookies". These cookies are used to store
            information including visitors' preferences, and the pages on the website that the
            visitor accessed or visited. The information is used to optimize the users' experience
            by customizing our web page content based on visitors' browser type and/or other
            information.
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Google DoubleClick DART Cookie
          </Heading>
          <Text mx={0}>
            Google is one of a third-party vendor on our site. It also uses cookies, known as DART
            cookies, to serve ads to our site visitors based upon their visit to www.website.com and
            other sites on the internet. However, visitors may choose to decline the use of DART
            cookies by visiting the Google ad and content network Privacy Policy at the following
            URL –{' '}
            <Link
              target={'_blank'}
              color={'green.700'}
              textDecoration={'underline'}
              fontWeight={'600'}
              href="https://policies.google.com/technologies/ads"
            >
              https://policies.google.com/technologies/ads
            </Link>
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Our Advertising Partners
          </Heading>
          <Text mx={0}>
            Some of advertisers on our site may use cookies and web beacons. Our advertising
            partners are listed below. Each of our advertising partners has their own Privacy Policy
            for their policies on user data. For easier access, we hyperlinked to their Privacy
            Policies below.
          </Text>
          <Text m={0} textIndent={'20px'}>
            {' '}
            - Google:{' '}
            <Link
              target={'_blank'}
              color={'green.700'}
              textDecoration={'underline'}
              fontWeight={'600'}
              href="https://policies.google.com/technologies/ads"
            >
              https://policies.google.com/technologies/ads
            </Link>
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Privacy Policies
          </Heading>
          <Text mx={0}>
            You may consult this list to find the Privacy Policy for each of the advertising
            partners of ecocrafts.
          </Text>
          <Text mx={0}>
            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
            Beacons that are used in their respective advertisements and links that appear on
            ecocrafts, which are sent directly to users' browser. They automatically receive your IP
            address when this occurs. These technologies are used to measure the effectiveness of
            their advertising campaigns and/or to personalize the advertising content that you see
            on websites that you visit.
          </Text>
          <Text mx={0}>
            Note that ecocrafts has no access to or control over these cookies that are used by
            third-party advertisers.
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Third Party Privacy Policies
          </Heading>
          <Text mx={0}>
            ecocrafts's Privacy Policy does not apply to other advertisers or websites. Thus, we are
            advising you to consult the respective Privacy Policies of these third-party ad servers
            for more detailed information. It may include their practices and instructions about how
            to opt-out of certain options.{' '}
          </Text>
          <Text mx={0}>
            You can choose to disable cookies through your individual browser options. To know more
            detailed information about cookie management with specific web browsers, it can be found
            at the browsers' respective websites.
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Children's Information
          </Heading>
          <Text mx={0}>
            Another part of our priority is adding protection for children while using the internet.
            We encourage parents and guardians to observe, participate in, and/or monitor and guide
            their online activity.
          </Text>
          <Text mx={0}>
            ecocrafts does not knowingly collect any Personal Identifiable Information from children
            under the age of 13. If you think that your child provided this kind of information on
            our website, we strongly encourage you to contact us immediately and we will do our best
            efforts to promptly remove such information from our records.
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Online Privacy Policy Only
          </Heading>
          <Text mx={0}>
            This Privacy Policy applies only to our online activities and is valid for visitors to
            our website with regards to the information that they shared and/or collect in
            ecocrafts. This policy is not applicable to any information collected offline or via
            channels other than this website.
          </Text>
          <Heading as={'h4'} mt={'60px'} size={'md'}>
            Consent
          </Heading>
          <Text mx={0}>
            By using our website, you hereby consent to our Privacy Policy and agree to its Terms
            and Conditions.
          </Text>
        </Box>
      </Section>
    </Layout>
  )
}

export default Privacy
