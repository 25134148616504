import React from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import {
  Text,
  Heading,
  VStack,
  Divider,
  CardHeader,
  Card,
  CardBody,
  Stack,
  Flex,
} from '@chakra-ui/react'
import Meta from 'components/meta'
import BulkOrder from 'components/partials/bulkorder'

const About = () => {
  return (
    <Layout>
      <Section>
        <Meta title="About Us" description="We elevate, enrich and foster the craft community." />
        <Flex px={{ sm: '3%', lg: '18%' }}>
          <Title text={'We elevate, enrich and foster the craft community'} titleAlign={'center'} />
        </Flex>
        <VStack spacing={8} align="stretch">
          <Text>
            <Text fontSize={'36px'} display={'inline'} fontWeight={'bolder'} color={'green.700'}>
              ecocrafts
            </Text>
            , a sanctuary where timeless tradition and eco-conscious craftsmanship converge. Our
            journey began with a simple yet profound mission: to celebrate the legacy of our
            artisans while nurturing the planet that sustains us all.
          </Text>

          <Stack
            direction={{ sm: 'column', lg: 'row' }}
            justifyContent={'space-between'}
            mt={5}
            spacing={8}
          >
            <Card
              w={{ sm: '100%', lg: '50%' }}
              align={{ sm: 'left', lg: 'center' }}
              variant={'outline'}
              borderRadius={'unset'}
              border={'none'}
            >
              <CardHeader>
                <Heading as="h3" size="lg">
                  Our Philosophy
                  <Divider borderColor={'gray.400'} />
                </Heading>
              </CardHeader>
              <CardBody>
                <Text lineHeight={9}>
                  At ecocrafts, we believe that true beauty lies in harmony with nature. Each of our
                  pieces is a testament to practices that honor the earth, crafted from
                  biodegradable materials like horn, brass, bamboo, jute, paddy straw, leather,
                  silver, cane, and terracotta. We are more than just a marketplace; we are
                  custodians of a heritage that dates back centuries, preserved through the hands of
                  tribal and rural artisans.
                </Text>
              </CardBody>
            </Card>
            <Card
              w={{ sm: '100%', lg: '50%' }}
              align={{ sm: 'left', lg: 'center' }}
              variant={'outline'}
              borderRadius={'unset'}
              border={'none'}
            >
              <CardHeader>
                <Heading as="h3" size="lg">
                  Our Artisans
                  <Divider borderColor={'gray.400'} />
                </Heading>
              </CardHeader>
              <CardBody>
                <Text lineHeight={9}>
                  The heart of ecocrafts beats with the rhythm of skilled craftsmen and women from
                  various tribes and rural communities. These artisans are the pillars of our
                  existence, infusing life into raw, natural materials with their imagination and
                  dexterity. By choosing ecocrafts, you're not just purchasing a product; you're
                  empowering the lives of these creators and ensuring their art thrives for
                  generations to come.
                </Text>
              </CardBody>
            </Card>
          </Stack>
          <Stack
            direction={{ sm: 'column', lg: 'row' }}
            justifyContent={'space-between'}
            spacing={8}
          >
            <Card
              w={{ sm: '100%', lg: '50%' }}
              align={{ sm: 'left', lg: 'center' }}
              variant={'outline'}
              borderRadius={'unset'}
              border={'none'}
            >
              <CardHeader>
                <Heading as="h3" size="lg">
                  Our Commitment
                  <Divider borderColor={'gray.400'} />
                </Heading>
              </CardHeader>
              <CardBody>
                <Text lineHeight={9}>
                  Sustainability isn't just a buzzword for us; it's a commitment etched into every
                  facet of our operation. From sourcing to production, we ensure that our processes
                  leave the lightest ecological footprint possible. We take pride in offering you a
                  collection that's as kind to the environment as it is to the aesthetic seeker.
                </Text>
              </CardBody>
            </Card>
            <Card
              w={{ sm: '100%', lg: '50%' }}
              align={{ sm: 'left', lg: 'center' }}
              variant={'outline'}
              borderRadius={'unset'}
              border={'none'}
            >
              <CardHeader>
                <Heading as="h3" size="lg">
                  Our Legacy
                  <Divider borderColor={'gray.400'} />
                </Heading>
              </CardHeader>
              <CardBody>
                <Text lineHeight={9}>
                  In a world racing towards the future, we pause to look back at the wisdom of our
                  ancestors. ecocrafts is a bridge between the past and the present, carrying
                  forward the legacy of sustainable living through crafts that tell a story—a story
                  of resilience, beauty, and an unbreakable bond with nature.
                </Text>
              </CardBody>
            </Card>
          </Stack>

          <Text my={'30px'}>
            <Text fontSize={'36px'} display={'inline'} fontWeight={'bolder'} color={'green.700'}>
              Join us
            </Text>{' '}
            on this journey. Embrace a piece of history, a moment of beauty, and a step towards a
            greener tomorrow.
          </Text>
        </VStack>
      </Section>
      <BulkOrder />
    </Layout>
  )
}

export default About
