import React, { useEffect, useState } from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import {
  Divider,
  Flex,
  Heading,
  Img,
  ListItem,
  OrderedList,
  SimpleGrid,
  Spinner,
  Text,
  UnorderedList,
  useBoolean,
  css,
} from '@chakra-ui/react'
import { useParams } from 'react-router'
import ArticleService from 'service/articles.service'
import ProductCard from 'components/partials/productcard'
import { bucket } from '../firebase'
import { getDownloadURL, ref } from 'firebase/storage'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import ReactMarkdown from 'react-markdown'
import defaultcard from 'styles/assets/adefaultcard.png'
import { Timestamp } from 'firebase/firestore'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import Meta from 'components/meta'

const Article = () => {
  const { articleid } = useParams()
  const [loading, setLoading] = useBoolean(true)
  const [imgLoading, setImgLoading] = useBoolean(true)
  const [contentLoading, setContentLoading] = useBoolean(true)
  const [article, setArticle] = useState(null)
  const [related, setRelated] = useState(null)
  const [content, setContent] = useState('')

  useEffect(() => {
    ArticleService.get(articleid).then((data) => {
      setArticle(data.details)
      setRelated(data?.related ?? null)
      setLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleid])

  useEffect(() => {
    if (article && article.content && content === '' && contentLoading) {
      const contentRef = ref(bucket, article.content)

      getDownloadURL(contentRef).then(async (url) => {
        const data = await fetch(url)

        if (data) {
          const document = await data.text()

          setContent(document)
        }
        setContentLoading.off()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, content, contentLoading])

  if (loading) {
    return (
      <Layout>
        <Flex h={'75vh'} w={'100%'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      </Layout>
    )
  }

  const mdTheme = {
    h1: (props) => {
      const { children } = props

      return (
        <Heading as={'h1'} mt={{ sm: '15px', lg: '30px' }} mb={{ sm: '5px', lg: '10px' }}>
          {children}
        </Heading>
      )
    },
    h2: (props) => {
      const { children } = props

      return (
        <Heading as={'h2'} mt={{ sm: '15px', lg: '30px' }} mb={{ sm: '5px', lg: '10px' }}>
          {children}
        </Heading>
      )
    },
    h3: (props) => {
      const { children } = props

      return (
        <Heading as={'h3'} mt={{ sm: '15px', lg: '30px' }} mb={{ sm: '5px', lg: '10px' }}>
          {children}
        </Heading>
      )
    },
    h4: (props) => {
      const { children } = props

      return (
        <Heading as={'h4'} mt={{ sm: '15px', lg: '30px' }} mb={{ sm: '5px', lg: '10px' }}>
          {children}
        </Heading>
      )
    },
    h5: (props) => {
      const { children } = props

      return (
        <Heading as={'h5'} mt={{ sm: '15px', lg: '30px' }} mb={{ sm: '5px', lg: '10px' }}>
          {children}
        </Heading>
      )
    },
    h6: (props) => {
      const { children } = props

      return (
        <Heading as={'h6'} mt={{ sm: '15px', lg: '30px' }} mb={{ sm: '5px', lg: '10px' }}>
          {children}
        </Heading>
      )
    },
    p: (props) => {
      const { children } = props

      return (
        <Text as={'p'} my={{ sm: '15px', lg: '30px' }} display={'inline-block'}>
          {children}
        </Text>
      )
    },
    ul: (props) => {
      const { children } = props

      return <UnorderedList listStyleType={'none'}>{children}</UnorderedList>
    },
    ol: (props) => {
      const { children } = props

      return <OrderedList listStyleType={'none'}>{children}</OrderedList>
    },
    li: (props) => {
      const { children } = props

      return (
        <ListItem mb={1}>
          <ArrowForwardIcon color={'green.700'} /> {children}
        </ListItem>
      )
    },
  }
  const handleLoad = () => {
    setTimeout(() => {
      setImgLoading.off()
    }, 1000)
  }
  const timeStamp = new Timestamp(article.created.seconds, article.created.nanoseconds).toDate()
  const createdAt = new Date(timeStamp).toLocaleDateString()

  return (
    <Layout>
      <Section>
        <Meta
          title={article.meta.title}
          description={article.meta.description}
          image={article.thumbnail}
          type="article"
        />
        <Title
          text={article?.title}
          stickyHeader={true}
          desc={
            <Flex>
              <Text>{createdAt}</Text>
              {article.properties?.tags && (
                <>
                  <Divider
                    orientation={'vertical'}
                    h={'20px'}
                    borderColor={'black'}
                    mx={4}
                    mt={1}
                  />
                  <Text style={{ textTransform: 'uppercase' }}>
                    {article.properties?.tags.join(', ')}
                  </Text>
                </>
              )}
            </Flex>
          }
        />
        <Flex
          id="article-details"
          alignItems={'left'}
          w={'100%'}
          direction={'column'}
          minHeight={'85vh'}
        >
          {!contentLoading && (
            <>
              <Flex
                flex={0.5}
                my={'30px'}
                h={{ sm: '420px', md: '640px' }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {article?.thumbnail && (
                  <Img
                    src={article?.thumbnail}
                    maxHeight={'100%'}
                    display={!imgLoading ? 'inline-block' : 'none'}
                    onLoad={handleLoad}
                  />
                )}
                {imgLoading && (
                  <Img
                    src={defaultcard}
                    maxHeight={'100%'}
                    zIndex={0}
                    h={{ sm: '420px', md: '640px' }}
                    display={'inline-block'}
                  />
                )}
              </Flex>
              <ReactMarkdown
                className="article-content"
                components={ChakraUIRenderer(mdTheme)}
                skipHtml
              >
                {content}
              </ReactMarkdown>
            </>
          )}
        </Flex>
        {related !== null && (
          <>
            <Divider borderColor={'gray.400'} my={{ sm: '30px', lg: '90px' }} />
            <Heading
              as={'h2'}
              textAlign={{ sm: 'left', lg: related.length > 2 ? 'center' : 'left' }}
              w={'100%'}
              mb={{ sm: '30px', lg: '90px' }}
            >
              From our shop
            </Heading>
            <Flex id="related-products" alignItems={'center'} w={'100%'} direction={'column'}>
              <SimpleGrid
                w={'100%'}
                gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
                mb={{ sm: '20px', md: '40px', lg: '60px' }}
                gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
                gridTemplateColumns={{ sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
                gridAutoRows={'1fr'}
                gridTemplateRows={'auto auto'}
                alignContent={'space-between'}
                css={css({
                  '@media print': {
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridColumnGap: '60px',
                    gridRowGap: '60px',
                  },
                })}
              >
                <>
                  {related.map((product, index) => (
                    <ProductCard product={product} key={`product-${product.id}-${index}`} />
                  ))}
                </>
              </SimpleGrid>
            </Flex>
          </>
        )}
      </Section>
    </Layout>
  )
}

export default Article
