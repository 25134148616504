import { collection, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { db } from '../firebase'

const ArticleService = {
  all: async () => {
    let articles = []
    const queryRef = query(collection(db, 'articles'), orderBy('created', 'desc'))
    const docSnap = await getDocs(queryRef)

    docSnap.forEach((article) => {
      articles.push({
        id: article.id,
        data: article.data(),
      })
    })
    return articles
  },
  get: async (articleid) => {
    try {
      const queryRef = query(collection(db, 'articles'), where('slug', '==', articleid), limit(1))
      const docSnap = await getDocs(queryRef)

      if (docSnap.size == 1) {
        const articleDoc = docSnap.docs[0]
        const articleSnap = articleDoc.data()

        const article = {
          details: articleSnap,
        }

        try {
          if (articleSnap?.related?.length > 0) {
            article.related = []
            for (let i = 0; i < articleSnap.related.length; i++) {
              const productDoc = await getDoc(articleSnap.related[i])

              article.related.push({
                data: productDoc.data(),
                id: productDoc.id,
              })
            }
          }
        } catch (e) {
          console.log(e)
        }
        return article
      }
    } catch (error) {
      console.log(error)
    }
    return null
  },
}

export default ArticleService
