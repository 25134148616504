import React from 'react'
import { Button, Flex, Img, Link, Text, css } from '@chakra-ui/react'
import logo from 'styles/assets/logo.png'
import { Link as ReactRouterLink } from 'react-router-dom'
import amazon from 'styles/assets/amazon.png'
import google from 'styles/assets/google.png'
import { StarIcon } from '@chakra-ui/icons'

const RatingIcon = () => (
  <Flex>
    {Array(5)
      .fill(null)
      .map((key, index) => (
        <StarIcon
          key={`star-${index}`}
          color={'#e7bd3f'}
          mr={'2px'}
          display={{ sm: 'none', md: 'inline' }}
        />
      ))}
  </Flex>
)

const Footer = () => {
  return (
    <Flex
      flex={1}
      direction={'column'}
      justifyContent={'flex-start'}
      py={{ sm: '30px', lg: '90px' }}
    >
      <Flex
        as={'nav'}
        className={'navbar-footer'}
        flex={1}
        direction={'column'}
        borderBottomWidth={1}
        borderBottomColor={'gray.300'}
        pb={'30px'}
        mb={'30px'}
      >
        <Flex h={'30px'} direction={'row'}>
          <Flex justifyContent={'flex-start'}>
            <Link tabIndex={0} to="/" as={ReactRouterLink} aria-label="Home" title="ecocrafts">
              <Img
                alt="ecocrafts"
                src={logo}
                h={{ sm: '40px', md: '58px' }}
                mt={{ sm: '-10px', md: '-20px' }}
                ml={{ sm: '-8px', md: '-12px' }}
                css={css({ '@media print': { height: '30px' } })}
              />
            </Link>
          </Flex>
          <Flex flex={1} justifyContent={'flex-end'} alignItems={'center'}>
            <Button
              href="https://g.page/r/CRE96AVtsqOOEAI/review"
              borderRadius={0}
              isExternal
              as={Link}
              rightIcon={<Img src={google} w={'20px'} h={'20px'} alt="Google" />}
              variant={'ghost'}
              color={'black'}
              size={'sm'}
              mt={'-2px'}
              alignItems={'center'}
              fontWeight={'normal'}
              _hover={{ background: '#f3f3f3', color: 'black', textDecor: 'none' }}
              _focus={{ background: '#f3f3f3', color: 'black' }}
              title="Review us on Google"
              leftIcon={<RatingIcon />}
            >
              Review us on
            </Button>
            <Link
              tabIndex={0}
              title={'Amazon Store'}
              href="https://www.amazon.in/storefront?me=A2RFLCXVCXWOSX"
              target="_blank"
              aria-label="amazon"
              ml={'16px'}
              _hover={{
                fontSize: 20,
                transform: 'scale(1.1)',
                transition: 'scale 0.3',
                background: 'transparent',
              }}
            >
              <Img src={amazon} w={'30px'} h={'30px'} alt="Amazon" />
            </Link>
          </Flex>
        </Flex>
      </Flex>
      <Flex h={'30px'} w={'100%'} wrap={'wrap'}>
        <Link title="Articles" as={ReactRouterLink} to="/articles" mr={'20px'} mb={'20px'}>
          Articles
        </Link>
        <Link title="Shop" as={ReactRouterLink} to="/products" mr={'20px'} mb={'20px'}>
          Shop
        </Link>
        <Link title="About" as={ReactRouterLink} to="/about" mr={'20px'} mb={'20px'}>
          About
        </Link>
        <Link title="Contact" as={ReactRouterLink} to="/contact" mr={'20px'} mb={'20px'}>
          Contact
        </Link>
        <Link title="Privacy" as={ReactRouterLink} to="/privacy" mr={'20px'} mb={'20px'}>
          Privacy
        </Link>
        {/* <Link as={ReactRouterLink} to="/faq" mr={'20px'} mb={'20px'}>
          Faq
        </Link> */}
        <Link title="Returns" as={ReactRouterLink} to="/returns" mr={'20px'} mb={'20px'}>
          Returns
        </Link>
      </Flex>
      <Flex h={'30px'} mt={'60px'}>
        <Text ml={0}>&copy; 2024, ecocrafts</Text>
      </Flex>
    </Flex>
  )
}

export default Footer
