import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase'

const HomeService = {
  best: async () => {
    let best = []
    const docRef = doc(db, 'home', 'best')
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const products = Object.values(docSnap.data())

      for (let i = 0; i < products.length; i++) {
        const productSnap = products[i]
        const productDoc = await getDoc(productSnap)

        best.push({
          data: productDoc.data(),
          id: productDoc.id,
        })
      }
    }
    return best
  },
  recent: async () => {
    let recent = {
      articles: null,
      products: [],
    }
    const docRef = doc(db, 'home', 'recent')
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const { articles, products } = docSnap.data()
      const articleSnap = articles[0]
      const articleDoc = await getDoc(articleSnap)

      recent.article = {
        data: articleDoc.data(),
        id: articleDoc.id,
      }

      for (let i = 0; i < products.length; i++) {
        const productSnap = products[i]
        const productDoc = await getDoc(productSnap)

        recent.products[i] = {
          data: productDoc.data(),
          id: productDoc.id,
        }
      }
    }
    return recent
  },
}

export default HomeService
