import { doc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'

const MessageService = {
  send: async (data, id) => {
    try {
      await setDoc(doc(db, 'messages', id), data)
      return true
    } catch (error) {
      console.log('test', error)
    }
    return false
  },
}

export default MessageService
