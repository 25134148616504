import React from 'react'
import { Helmet } from 'react-helmet-async'

const initialDetails = {
  title: 'ecocrafts',
  description: "Ecofreindly, Sustainable, Nature's crafts",
  type: 'article',
  name: 'ecocrafts',
  image: 'https://storage.googleapis.com/ecocrafts-web.appspot.com/site/ecocrafts_brand.png',
}

const Meta = ({
  title = initialDetails.title,
  description = initialDetails.description,
  name = initialDetails.name,
  type = initialDetails.type,
  image = initialDetails.image,
}) => (
  <Helmet>
    <title>{title} - ecocrafts</title>
    <link rel="canonical" href={document.location.href} />
    <meta name="description" content={description} />
    <meta name="apple-mobile-web-app-title" content={title} />
    <meta property="og:type" content={type} />
    <meta property="og:locale" content="en" />
    <meta property="og:title" content={title} />
    <meta property="og:image" content={image} />
    <meta property="twitter:image" content={image} />
    <meta property="og:description" content={description} />
    <meta name="twitter:creator" content={name} />
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="rating" content="safe for kids" />
  </Helmet>
)

export default Meta
