import React, { useEffect, useState } from 'react'
import Layout, { Section } from '../components/layout'
import { Box, VStack, Flex, SimpleGrid, Spinner, useBoolean, css } from '@chakra-ui/react'
import Title from 'components/partials/title'
import ProductService from 'service/products.service'
import ProductCard from 'components/partials/productcard'
import { useParams } from 'react-router'
import Meta from 'components/meta'

const Category = () => {
  const { category } = useParams()
  const [categoryDetails, setCategoryDetails] = useState(null)
  const [loading, setLoading] = useBoolean(true)
  const [products, setProducts] = useState([])

  useEffect(() => {
    ProductService.category(category).then((data) => {
      setProducts(data.products)
      setCategoryDetails(data.details)
      setLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  if (loading) {
    return (
      <Layout>
        <Flex h={'75vh'} w={'100%'} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      </Layout>
    )
  }
  return (
    <Layout>
      <Section>
        <Meta
          title={categoryDetails.meta.title}
          description={categoryDetails.meta.description}
          image={categoryDetails.images[0]}
        />
        <Title
          text={categoryDetails?.name ?? 'Products'}
          image={categoryDetails?.images[0]}
          desc={categoryDetails?.description}
        />
        <Flex
          id="related-products"
          alignItems={'center'}
          w={'100%'}
          direction={'column'}
          minHeight={'85vh'}
        >
          <SimpleGrid
            w={'100%'}
            gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
            mb={{ sm: '20px', md: '40px', lg: '60px' }}
            gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridTemplateColumns={{ sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
            gridAutoRows={'1fr'}
            gridTemplateRows={'auto auto'}
            alignContent={'space-between'}
            css={css({
              '@media print': {
                gridTemplateColumns: '1fr 1fr 1fr',
                gridColumnGap: '60px',
                gridRowGap: '60px',
              },
            })}
          >
            {loading ? (
              <>
                {Array(5)
                  .fill('')
                  .map((key, index) => (
                    <VStack key={`catg-${index}`} w={'100%'} alignItems={'flex-start'}>
                      <Box bg="gray.100" h={{ sm: '200px', md: '240px', lg: '360px' }} w={'100%'} />
                      <Box bg="gray.100" h={{ sm: '20px', md: '30px', lg: '30px' }} w={'60%'} />
                      <Box bg="gray.100" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'30%'} />
                    </VStack>
                  ))}
              </>
            ) : (
              <>
                {products.map((product, index) => (
                  <ProductCard product={product} key={`product-${product.id}`} />
                ))}
              </>
            )}
          </SimpleGrid>
        </Flex>
      </Section>
    </Layout>
  )
}

export default Category
