import React, { useEffect, useState } from 'react'
import Layout, { Section } from '../components/layout'
import { Box, VStack, Flex, SimpleGrid, useBoolean, Button, css } from '@chakra-ui/react'
import Title from 'components/partials/title'
import ProductService from 'service/products.service'
import ProductCard from 'components/partials/productcard'
import Meta from 'components/meta'
import { ChevronRightIcon } from '@chakra-ui/icons'

const Products = () => {
  const [loading, setLoading] = useBoolean(true)
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(0)
  const [last, setLast] = useState(null)

  const loadData = (append = false) => {
    ProductService.all(last).then((data) => {
      if (append) {
        setProducts([...products, ...data.data])
      } else {
        setProducts(data.data)
      }
      setLast(data.last)
      setLoading.off()
    })
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (page > 0 && last !== null) {
      loadData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Layout>
      <Section id="products">
        <Meta title="Products" />
        <Title text={'Products'} />
        <Flex alignItems={'center'} w={'100%'} direction={'column'} minHeight={'85vh'}>
          <SimpleGrid
            w={'100%'}
            gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
            mb={{ sm: '20px', md: '40px', lg: '60px' }}
            gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridTemplateColumns={{ sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
            gridAutoRows={'1fr'}
            gridTemplateRows={'auto auto'}
            alignContent={'space-between'}
            css={css({
              '@media print': {
                gridTemplateColumns: '1fr 1fr 1fr',
                gridColumnGap: '60px',
                gridRowGap: '60px',
              },
            })}
          >
            {loading ? (
              <>
                {Array(5)
                  .fill('')
                  .map((key, index) => (
                    <VStack key={`key-${index}`} w={'100%'} alignItems={'flex-start'}>
                      <Box bg="#f3f3f3" h={{ sm: '200px', md: '240px', lg: '360px' }} w={'100%'} />
                      <Box bg="#f3f3f3" h={{ sm: '20px', md: '30px', lg: '30px' }} w={'60%'} />
                      <Box bg="#f3f3f3" h={{ sm: '10px', md: '10px', lg: '10px' }} w={'30%'} />
                    </VStack>
                  ))}
              </>
            ) : (
              <>
                {products.map((product, index) => (
                  <ProductCard product={product} key={`product-${product.id}-${index}`} />
                ))}
              </>
            )}
          </SimpleGrid>
        </Flex>
        {last && (
          <Flex
            w={'100%'}
            alignItems={'center'}
            my={'60px'}
            justifyContent={'center'}
            overflow={'hidden'}
            position={'relative'}
          >
            <Button
              variant={'outline'}
              bg={'white'}
              borderWidth={2}
              borderColor={'black'}
              color={'black'}
              size={'lg'}
              w={{ sm: '100%', lg: '30%' }}
              borderRadius={0}
              disabled={loading}
              alignItems={'center'}
              _hover={{ background: 'black', color: 'white' }}
              _focus={{ background: 'black', color: 'white' }}
              onClick={() => setPage(page + 1)}
            >
              Load more ...
            </Button>
          </Flex>
        )}
      </Section>
    </Layout>
  )
}

export default Products
