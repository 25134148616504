import React, { useEffect, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  Heading,
  Divider,
  useBoolean,
} from '@chakra-ui/react'
import ProductService from 'service/products.service'

const Menu = ({ isOpen, onClose, btnRef }) => {
  const [loading, setLoading] = useBoolean(true)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    ProductService.categories().then((data) => {
      setCategories(data)
      setLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton borderRadius={'none'} />
        <DrawerBody p={8} mt={10}>
          <Flex
            as={'nav'}
            justifyContent={'flex-start'}
            wrap={'wrap'}
            alignItems={'flex-start'}
            bgColor={'black.400'}
            color={'white.600'}
            overflow={'visible'}
            direction={'column'}
            h={'100%'}
          >
            <Flex flexGrow={1} direction={'column'} width={'100%'}>
              <Heading as={'h6'} size={'xl'} mb={'20px'} w={'100%'}>
                <Link
                  as={ReactRouterLink}
                  to={'/products'}
                  onClick={onClose}
                  w={'100%'}
                  display={'inline-block'}
                >
                  Products
                </Link>
              </Heading>
              {!loading && (
                <Flex direction={'column'} w={'100%'}>
                  {categories.map((category, index) => (
                    <Link
                      mb={'10px'}
                      key={`cat-${category.id}`}
                      as={ReactRouterLink}
                      to={`/products/${category.data.slug}`}
                      onClick={onClose}
                      w={'100%'}
                      display={'inline-block'}
                    >
                      {category.data.name}
                    </Link>
                  ))}
                </Flex>
              )}
              <Divider my={{ sm: '20px', base: '50px' }} />
              <Heading as={'h6'} size={'xl'} mb={'20px'} w={'100%'}>
                <Link
                  as={ReactRouterLink}
                  to={'/articles'}
                  onClick={onClose}
                  w={'100%'}
                  display={'inline-block'}
                >
                  Articles
                </Link>
              </Heading>
            </Flex>
            <Heading as={'h6'} size={'xl'} mb={'20px'} w={'100%'}>
              <Link
                as={ReactRouterLink}
                to={'/about'}
                onClick={onClose}
                w={'100%'}
                display={'inline-block'}
              >
                About
              </Link>
            </Heading>
            <Heading as={'h6'} size={'xl'} mb={'20px'} w={'100%'}>
              <Link
                as={ReactRouterLink}
                to={'/contact'}
                onClick={onClose}
                w={'100%'}
                display={'inline-block'}
              >
                Contact
              </Link>
            </Heading>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default Menu
