import React, { useEffect, useState } from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  css,
  Link,
  useBoolean,
  Stack,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import Meta from 'components/meta'
import Whatsapp from '../styles/assets/whatsapp-catalogue.svg'
import HomeService from 'service/home.service'
import ProductCard from 'components/partials/productcard'

const Home = () => {
  const [bestLoading, setBestLoading] = useBoolean(true)
  const [recentLoading, setRecentLoading] = useBoolean(true)
  const [recent, setRecent] = useState({ articles: [], products: [] })
  const [best, setBest] = useState([])

  useEffect(() => {
    HomeService.recent().then((data) => {
      setRecent(data)
      setRecentLoading.off()
    })
    HomeService.best().then((data) => {
      setBest(data)
      setBestLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Section>
        <Meta title="Home" />
        <Title text={'Welcome'} desc={'Checkout our artifacts...'} />
        <Box h={'30vh'}>
          <Button
            as={ReactRouterLink}
            to="/products"
            p={8}
            borderWidth={2}
            borderColor={'black'}
            bg={'black'}
            color={'white'}
            borderRadius={0}
            alignItems={'center'}
            _hover={{ background: 'white', color: 'black' }}
            _focus={{ background: 'white', color: 'black' }}
            flexGrow={1}
          >
            Shop now
          </Button>
        </Box>
        <Flex
          alignItems={'center'}
          w={'100%'}
          direction={'column'}
          mb={{ sm: '30px', md: '60px', lg: '60px' }}
        >
          <Heading
            as={'h2'}
            title="New Products"
            mb={{ sm: '30px', lg: '60px' }}
            fontWeight={'300'}
          >
            New in Shop
          </Heading>
          <SimpleGrid
            w={'100%'}
            gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
            gridAutoRows={'1fr'}
            gridTemplateRows={'auto auto'}
            alignContent={'space-between'}
            css={css({
              '@media print': {
                gridTemplateColumns: '1fr 1fr 1fr',
                gridColumnGap: '60px',
                gridRowGap: '60px',
              },
            })}
          >
            {recentLoading ? (
              <>
                {Array(3)
                  .fill('')
                  .map((key, index) => (
                    <Stack
                      key={`key-${index}`}
                      w={'100%'}
                      alignItems={'flex-start'}
                      direction={{ sm: 'row', md: 'column' }}
                    >
                      <Box
                        bg="#f3f3f3"
                        h={{ sm: '240px', md: '240px', lg: '360px' }}
                        w={{ sm: '50%', md: '100%', lg: '100%' }}
                      />
                      <Flex
                        w={{ sm: '50%', md: '100%', lg: '100%' }}
                        direction={'column'}
                        justifyContent={'center'}
                        alignContent={'center'}
                        my={'auto'}
                      >
                        <Box bg="#f3f3f3" h={'30px'} w={{ sm: '90%', md: '60%' }} mb={'10px'} />
                        <Box bg="#f3f3f3" h={'10px'} w={{ sm: '60%', md: '30%' }} mb={'10px'} />
                      </Flex>
                    </Stack>
                  ))}
              </>
            ) : (
              <>
                {recent.products?.map((product, index) => (
                  <ProductCard
                    product={product}
                    key={`product-${product.id}-${index}`}
                    smMode={'split'}
                  />
                ))}
              </>
            )}
          </SimpleGrid>
        </Flex>
        <Flex
          alignItems={'center'}
          w={'100%'}
          direction={'column'}
          mb={{ sm: '30px', md: '60px', lg: '60px' }}
        >
          <Heading as={'h2'} title="Bestsellers" mb={{ sm: '30px', lg: '60px' }} fontWeight={'300'}>
            Best in Shop
          </Heading>
          <SimpleGrid
            w={'100%'}
            gridRowGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
            gridTemplateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
            gridAutoRows={'1fr'}
            gridTemplateRows={'auto auto'}
            alignContent={'space-between'}
            css={css({
              '@media print': {
                gridTemplateColumns: '1fr 1fr 1fr',
                gridColumnGap: '60px',
                gridRowGap: '60px',
              },
            })}
          >
            {bestLoading ? (
              <>
                {Array(3)
                  .fill('')
                  .map((key, index) => (
                    <Stack
                      key={`key-${index}`}
                      w={'100%'}
                      alignItems={'flex-start'}
                      direction={{ sm: 'row', md: 'column' }}
                    >
                      <Box
                        bg="#f3f3f3"
                        h={{ sm: '240px', md: '240px', lg: '360px' }}
                        w={{ sm: '50%', md: '100%', lg: '100%' }}
                      />
                      <Flex
                        w={{ sm: '50%', md: '100%', lg: '100%' }}
                        direction={'column'}
                        justifyContent={'center'}
                        alignContent={'center'}
                        my={'auto'}
                      >
                        <Box bg="#f3f3f3" h={'30px'} w={{ sm: '90%', md: '60%' }} mb={'10px'} />
                        <Box bg="#f3f3f3" h={'10px'} w={{ sm: '60%', md: '30%' }} mb={'10px'} />
                      </Flex>
                    </Stack>
                  ))}
              </>
            ) : (
              <>
                {best?.map((product, index) => (
                  <ProductCard
                    product={product}
                    key={`product-${product.id}-${index}`}
                    smMode={'split'}
                  />
                ))}
              </>
            )}
          </SimpleGrid>
          <Button
            as={ReactRouterLink}
            to={'/products'}
            borderRadius={0}
            bg={'transparent'}
            borderWidth={2}
            borderColor={'black'}
            color={'black'}
            _hover={{ background: 'black', color: 'white' }}
            _focus={{ background: 'black', color: 'white' }}
            isLoading={bestLoading}
          >
            Visit Shop
          </Button>
        </Flex>
      </Section>
      <Section full background={'#f3f3f3'}>
        <Flex
          id="whatsapp-contact"
          direction={{ sm: 'column', lg: 'row' }}
          justifyContent={{ sm: 'space-between', lg: 'flex-end' }}
          alignItems={'center'}
          my={'30px'}
          w={'100%'}
          className="container"
        >
          <Heading>
            You can also visit us on{' '}
            <Link color={'green.700'} target="_blank" href="https://wa.me/918763326998">
              Whatsapp
            </Link>
          </Heading>
          <Image
            src={Whatsapp}
            w={{ sm: '240px', lg: '180px' }}
            title="Scan to contact on whatsapp"
            ml={'30px'}
            css={css({
              '@media print': {
                w: '180px',
              },
            })}
            bg={'white'}
            borderColor={'green.600'}
            borderWidth={'3px'}
            borderStyle={'solid'}
          />
        </Flex>
      </Section>
      <Section>
        <SimpleGrid
          w={'100%'}
          gridRowGap={{ sm: '20px', lg: '30px' }}
          my={{ sm: '20px', lg: '30px' }}
          gridColumnGap={{ sm: '20px', md: '40px', lg: '60px' }}
          gridTemplateColumns={{ sm: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
          gridAutoRows={'1fr'}
          gridTemplateRows={'auto auto'}
          alignContent={'space-between'}
          css={css({
            '@media print': {
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridColumnGap: '60px',
              gridRowGap: '30px',
            },
          })}
        >
          <Flex
            maxH={'255px'}
            bg={'#f3f3f3'}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
            position={'relative'}
            css={css({
              '@media print': {
                height: '200px',
                width: '200px',
              },
            })}
          >
            <Title text={'7+'} desc={'Skilled Artists'} titleAlign="center" />
          </Flex>
          <Flex
            maxH={'255px'}
            bg={'#f3f3f3'}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
            position={'relative'}
            css={css({
              '@media print': {
                height: '200px',
                width: '200px',
              },
            })}
          >
            <Title text={'25+'} desc={'Ecofriendly Products'} titleAlign="center" />
          </Flex>
          <Flex
            maxH={'255px'}
            bg={'#f3f3f3'}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
            position={'relative'}
            css={css({
              '@media print': {
                height: '200px',
                width: '200px',
              },
            })}
          >
            <Title text={'240+'} desc={'Satisfied Customers'} titleAlign="center" />
          </Flex>
          <Flex
            maxH={'255px'}
            bg={'#f3f3f3'}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            overflow={'hidden'}
            position={'relative'}
            css={css({
              '@media print': {
                height: '200px',
                width: '200px',
              },
            })}
          >
            <Title text={'9+'} desc={'Daily Orders'} titleAlign="center" />
          </Flex>
        </SimpleGrid>
      </Section>
    </Layout>
  )
}

export default Home
