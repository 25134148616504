import React from 'react'
import { Button, Flex, Heading, Link } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import Meta from 'components/meta'

const NoPage = () => {
  return (
    <Layout>
      <Section>
        <Meta title="Error - Invalid link" />
        <Title text={'Sorry. Invalid link'} />
        <Flex h={'75vh'} direction={'column'}>
          <Heading as={'h5'} size={'xl'}>
            The page you are looking for doesn't exist or has been moved.
          </Heading>
          <Heading as={'h5'} size={'l'} mb={'100px'}>
            We are extremely sorry for the inconvinince caused to you.
          </Heading>
          <Button
            as={ReactRouterLink}
            to="/"
            borderRadius={0}
            width={'200px'}
            bg={'transparent'}
            borderWidth={2}
            borderColor={'black'}
          >
            Go back Home
          </Button>
        </Flex>
      </Section>
    </Layout>
  )
}

export default NoPage
