import React, { useEffect, useLayoutEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import './app.scss'
import Home from 'screens/home.screen'
import Products from 'screens/products.screen'
import Product from 'screens/product.screen'
import Articles from 'screens/articles.screen'
import Category from 'screens/category.screen'
import About from 'screens/about.screen'
import Contact from 'screens/contact.screen'
import Privacy from 'screens/privacy.screen'
import Faq from 'screens/faq.screen'
import Returns from 'screens/returns.screen'
import NoPage from 'screens/nopage.screen'
import Article from 'screens/article.screen'
import { deleteApp } from 'firebase/app'
import app from './firebase'

const App = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  // @ts-ignore
  useEffect(() => {
    const unloadCallback = async () => {
      await deleteApp(app)
    }

    window.addEventListener('beforeunload', unloadCallback)
    return async () => {
      window.removeEventListener('beforeunload', unloadCallback)
    }
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/article/:articleid" element={<Article />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products" element={<Products />} />
      <Route path="/product/:productid" element={<Product />} />
      <Route path="/products/:category" element={<Category />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/license" element={<Navigate to="/privacy" replace />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/returns" element={<Returns />} />
      <Route path="/error" element={<NoPage />} />
      <Route path="*" element={<Navigate to="/error" replace />} />
    </Routes>
  )
}

export default App
