import React, { useState } from 'react'
import Layout, { Section } from '../components/layout'
import Title from 'components/partials/title'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
  VStack,
  useBoolean,
  useToast,
  css,
} from '@chakra-ui/react'
import MessageService from 'service/message.service'
import { static_assets_url } from '../firebase'
import Meta from 'components/meta'
import Whatsapp from '../styles/assets/whatsapp-catalogue.svg'

const Contact = () => {
  const [loading, setLoading] = useBoolean(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState(null)
  const toast = useToast()

  const handleNameChange = (e) => setName(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handleMessageChange = (e) => setMessage(e.target.value)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const errorsList = {}
    const namePattern = /^[a-zA-Z-'. ]+$/
    const emailPattern = /^\S+@\S+\.\S+$/

    if (name === null || name.trim() === '') {
      errorsList.name = 'required'
    } else if (name.length < 3 || !namePattern.test(name)) {
      errorsList.name = 'invalid'
    }

    if (email === null || email.trim() === '') {
      errorsList.email = 'required'
    } else if (email.length < 6 || !emailPattern.test(email)) {
      errorsList.email = 'invalid'
    }

    if (message === null || message.trim() === '') {
      errorsList.message = 'required'
    } else if (message.length > 2000) {
      errorsList.message = 'invalid'
    }

    if (Object.keys(errorsList).length > 0) {
      setErrors(errorsList)
    } else {
      setErrors(null)
      setLoading.on()
      const data = {
        name,
        email,
        message: encodeURIComponent(message),
        phone: '',
        category: 'contact',
      }
      const doc_id = email.replace('@', '[at]').replace('.', '[dot]')

      MessageService.send(data, doc_id).then((status) => {
        setLoading.off()
        if (status) {
          setName('')
          setEmail('')
          setMessage('')
          toast({
            position: 'bottom-right',
            duration: 6000,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="black">
                <Heading as={'h6'} size={'s'} mb={0}>
                  Thank you {data.name}
                </Heading>
                <Text as={'small'} fontWeight={'600'}>
                  We'll address your valuable message as soon as possible.
                </Text>
              </Box>
            ),
          })
        } else {
          toast({
            position: 'bottom-right',
            duration: 6000,
            isClosable: true,
            render: () => (
              <Box
                color="white"
                p={3}
                bg="black"
                as={'a'}
                href={`mailto:ecocraftsbbsr@gmail.com?subject=Hello!!&body=${encodeURIComponent(message)}`}
                display={'block'}
              >
                <Heading as={'h6'} size={'s'} mb={0}>
                  Sorry {data.name}
                </Heading>
                <Text as={'small'} fontWeight={'600'}>
                  Please try again, you can always drop us an email.
                </Text>
              </Box>
            ),
          })
        }
      })
    }
    return false
  }

  const apiKey = 'AIzaSyDQ5yE1skmnIgV92xlvXPJkS6ut3OT8mwM'
  const mapUrl = `https://www.google.com/maps/embed/v1/place?zoom=15&maptype=satellite&q=Block-B%2C%20Metro%20Satellite%20City%2C%20Phase-III&key=${apiKey}`

  return (
    <Layout>
      <Section id="contact-details">
        <Meta title="Contact Us" />
        <Stack direction={{ sm: 'column', md: 'row', base: 'row' }} w={'100%'} mb={'30px'}>
          <VStack alignItems={'flex-start'} w={'100%'} flex={0.5}>
            <Title
              text={'Contact'}
              desc={
                <Text as={'h6'}>
                  Want to get in touch? <br />
                  We'd{' '}
                  <Text
                    as={'b'}
                    color={'pink.500'}
                    display={'inline-block'}
                    textShadow={'0 0 8px var(--chakra-colors-pink-300)'}
                    animation={'heartbeat 1.2s infinite'}
                  >
                    love
                  </Text>{' '}
                  to hear from you. Here's how you can reach us...
                </Text>
              }
            />
            <Flex />
            <Heading as={'h5'}>Address</Heading>
            <Text as={'address'} mb={'60px'} fontStyle={'normal'}>
              ecocrafts, Phase-III, Metro Satellite City
              <br />
              Hanspal, Bhubaneshwar
              <br />
              Odisha, INDIA - 752101
            </Text>
            <Heading as={'h5'}>Web</Heading>
            <Link as={'a'} href="mailto:ecocraftsbbsr@gmail.com">
              Email: ecocraftsbbsr@gmail.com
            </Link>
            <Text mb={'20px'}>
              Gst:{' '}
              <Text as={'code'} fontSize={'13px'}>
                21FJGPP5500N1Z4
              </Text>
            </Text>
          </VStack>
          <VStack flex={0.5} pt={{ sm: '40px', lg: '90px' }} justifyContent={'flex-end'}>
            <Image src={`${static_assets_url}/site/ecocrafts_brand.png`} />
          </VStack>
        </Stack>
      </Section>
      <Section id="whatsapp" full sectionProps={{ bg: '#f3f3f3', py: '30px' }}>
        <Flex
          className="container"
          id="whatsapp-catalogue"
          direction={{ sm: 'column', lg: 'row' }}
          justifyContent={{ sm: 'space-between', lg: 'flex-end' }}
          alignItems={'center'}
          w={'100%'}
        >
          <Heading>
            You can also visit us on{' '}
            <Link color={'green.700'} target="_blank" href="https://wa.me/918763326998">
              Whatsapp
            </Link>
          </Heading>
          <Image
            src={Whatsapp}
            w={{ sm: '240px', lg: '180px' }}
            title="Scan to contact on whatsapp"
            ml={'30px'}
            css={css({
              '@media print': {
                w: '180px',
              },
            })}
          />
        </Flex>
      </Section>
      <Section id="contact-form" full sectionProps={{ bg: 'black', color: 'white', pb: '30px' }}>
        <VStack
          w={'100%'}
          alignItems={'flex-start'}
          my={{ md: '30px' }}
          as={'form'}
          onSubmit={handleFormSubmit}
          name="drop-message"
          className="container"
        >
          <Heading as={'h4'} my={'30px'} w={'100%'} textAlign={{ sm: 'center', md: 'left' }}>
            Drop a message
          </Heading>
          <Stack
            w={'100%'}
            spacing={8}
            mb={{ sm: '15px', md: '30px' }}
            direction={{ sm: 'column', md: 'row', base: 'row' }}
            px={{ sm: '40px', md: 0 }}
          >
            <FormControl isRequired isInvalid={errors?.name === 'invalid'}>
              <Input
                variant="flushed"
                name="name"
                value={name}
                placeholder="Your good name"
                onChange={handleNameChange}
                _focus={{ borderBottomColor: 'white', boxShadow: '0 1px 0 white' }}
              />
              {errors?.name === 'required' && (
                <FormHelperText>Name cannot be empty.</FormHelperText>
              )}
              {errors?.name === 'invalid' && <FormErrorMessage>Name is invalid.</FormErrorMessage>}
            </FormControl>
            <FormControl isRequired isInvalid={errors?.email === 'invalid'}>
              <Input
                variant="flushed"
                name="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Your email address"
                _focus={{ borderBottomColor: 'white', boxShadow: '0 1px 0 white' }}
              />
              {errors?.email === 'required' && (
                <FormHelperText>Email cannot be empty.</FormHelperText>
              )}
              {errors?.email === 'invalid' && (
                <FormErrorMessage>Email is invalid.</FormErrorMessage>
              )}
            </FormControl>
          </Stack>
          <FormControl
            isRequired
            isInvalid={errors?.message === 'invalid'}
            px={{ sm: '40px', md: 0 }}
          >
            <Textarea
              placeholder="Your valuable message"
              name="message"
              resize={'vertical'}
              variant={'flushed'}
              cols={4}
              value={message}
              onChange={handleMessageChange}
              minHeight={'120px'}
              maxHeight={'320px'}
              _focus={{ borderBottomColor: 'white', boxShadow: '0 1px 0 white' }}
            />
            {errors?.message === 'required' && (
              <FormHelperText>Message cannot be empty.</FormHelperText>
            )}
            {errors?.message === 'invalid' && (
              <FormErrorMessage>Message is exceeding length of 2000 characters.</FormErrorMessage>
            )}
          </FormControl>
          <Stack alignItems={{ sm: 'center', md: 'flex-end' }} w={'100%'} mt={'30px'}>
            <Button
              borderRadius={0}
              bg={'transparent'}
              borderWidth={2}
              borderColor={'white'}
              color={'white'}
              p={6}
              _hover={{ background: 'white', color: 'black' }}
              _focus={{ background: 'white', color: 'black' }}
              type="submit"
              isLoading={loading}
              loadingText="Sending"
              spinnerPlacement="end"
            >
              Send now
            </Button>
          </Stack>
        </VStack>
      </Section>
      <Section id="location-details" full sectionProps={{ bg: '#f3f3f3', py: '30px' }}>
        <Flex className="container">
          <Heading as={'h4'} mb={'30px'}>
            We are here...
          </Heading>
          <Flex
            direction={{ sm: 'column', lg: 'row' }}
            w={'100%'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Box
              as={'iframe'}
              w={'100%'}
              h={'450px'}
              borderWidth={0}
              loading="lazy"
              flexGrow={1}
              frameBorder={0}
              referrerPolicy="no-referrer-when-downgrade"
              src={mapUrl}
            />
            <Flex w={'20px'} h={'30px'} />
            <Flex
              as={'a'}
              href={'https://www.amazon.in/storefront?me=A2RFLCXVCXWOSX'}
              h={'450px'}
              borderWidth={1}
              borderColor={'gray.600'}
              justifyContent={'flex-start'}
            >
              <Image minWidth={'290px'} src={`${static_assets_url}/site/amazon_store.png`} />
            </Flex>
          </Flex>
        </Flex>
      </Section>
    </Layout>
  )
}

export default Contact
