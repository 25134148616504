import { ArrowUpIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, Img, useBoolean, css } from '@chakra-ui/react'
import React, { isValidElement, useEffect } from 'react'
import defaultcard from 'styles/assets/cdefaultcard.png'

const Title = ({
  text,
  desc = null,
  children = null,
  image = null,
  titleAlign = 'left',
  stickyHeader = false,
  id = '',
}) => {
  const [loading, setLoading] = useBoolean(image !== null ? true : false)
  const [sticky, setSticky] = useBoolean(false)
  const handleLoad = () => {
    setTimeout(() => {
      setLoading.off()
    }, 1000)
  }
  const isSticky = () => {
    const scrollTop = window.scrollY

    scrollTop >= 150 ? setSticky.on() : setSticky.off()
  }

  useEffect(() => {
    if (stickyHeader) {
      window.addEventListener('scroll', isSticky)
      return () => {
        window.removeEventListener('scroll', isSticky)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stickyHeader])

  return (
    <>
      {stickyHeader && sticky && (
        <Flex
          mt={0}
          className={'container sticky'}
          css={css({
            '@media print': {
              display: 'none',
            },
          })}
        >
          <Flex
            ml={{ sm: '-15px', md: '-30px' }}
            h={{ sm: '60px', md: '80px' }}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'space-between'}
            borderBottomWidth={1}
            borderBottomColor={'gray.300'}
            bg={'rgba(255, 255, 255, 0.8)'}
            backdropFilter="auto"
            backdropBlur="60px"
          >
            <Heading
              as={'h1'}
              fontSize={{ sm: '20px', md: '26px' }}
              aria-label="title"
              textAlign={titleAlign}
              textShadow={'md'}
              w={'100%'}
            >
              {text}
            </Heading>
            <IconButton
              borderRadius={0}
              bg={'transparent'}
              aria-label={'Back to Top'}
              icon={<ArrowUpIcon boxSize={{ sm: 6, md: 8 }} />}
              title={'Back to Top'}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            />
          </Flex>
        </Flex>
      )}
      <Flex
        w={'100%'}
        justifyContent={'center'}
        py={{ sm: '40px', lg: '90px' }}
        alignItems={'flex-start'}
        direction={'row'}
        id={id}
        css={css({
          '@media print': {
            py: '30px',
          },
        })}
      >
        <Flex flexGrow={1} direction={'column'} alignItems={'flex-start'}>
          <Heading
            as={'h1'}
            size={{ sm: '2xl', md: '3xl' }}
            aria-label="title"
            textAlign={titleAlign}
            w={'100%'}
            mb={'15px'}
          >
            {text}
          </Heading>
          {desc && (
            <>
              {isValidElement(desc) ? (
                <Box textAlign={titleAlign} aria-label="description" w={'100%'}>
                  {desc}
                </Box>
              ) : (
                <Heading
                  aria-label="description"
                  as={'h2'}
                  size={'sm'}
                  fontWeight={200}
                  textAlign={titleAlign}
                  w={'100%'}
                >
                  {desc}
                </Heading>
              )}
            </>
          )}

          <Flex justifyContent={'flex-start'}>{children}</Flex>
        </Flex>
        {image && (
          <Flex ml={'30px'} maxW={'150px'} minW={'150px'} overflow={'hidden'} borderWidth={1}>
            <Img
              src={image}
              maxHeight={'100%'}
              verticalAlign={'middle'}
              display={!loading ? 'inline-block' : 'none'}
              _hover={{ transform: 'scale(1.2)', transition: 'transform 0.6s' }}
              onLoad={handleLoad}
            />
            {loading && (
              <Img src={defaultcard} maxHeight={'100%'} zIndex={0} display={'inline-block'} />
            )}
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Title
