import React, { useEffect, useState } from 'react'
import { Section } from '../layout'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
  useBoolean,
  useToast,
} from '@chakra-ui/react'
import MessageService from 'service/message.service'
import ProductService from 'service/products.service'

const BulkOrder = () => {
  const [loading, setLoading] = useBoolean(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [category, setCategory] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState(null)
  const toast = useToast()

  const handleNameChange = (e) => setName(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handleMessageChange = (e) => setMessage(e.target.value)
  const handlePhoneChange = (e) => setPhone(e.target.value)
  const handleCategoryChange = (e) => setCategory(e.target.value)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const errorsList = {}
    const namePattern = /^[a-zA-Z-'. ]+$/
    const emailPattern = /^\S+@\S+\.\S+$/
    const phonePattern =
      /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/g

    if (name === null || name.trim() === '') {
      errorsList.name = 'required'
    } else if (name.length < 3 || !namePattern.test(name)) {
      errorsList.name = 'invalid'
    }

    if (email === null || email.trim() === '') {
      errorsList.email = 'required'
    } else if (email.length < 6 || !emailPattern.test(email)) {
      errorsList.email = 'invalid'
    }

    if (phone === null || phone.trim() === '') {
      errorsList.phone = 'required'
    } else if (phone.length < 10 || !phonePattern.test(phone)) {
      errorsList.phone = 'invalid'
    }

    if (message === null || message.trim() === '') {
      errorsList.message = 'required'
    } else if (message.length > 2000) {
      errorsList.message = 'invalid'
    }

    if (Object.keys(errorsList).length > 0) {
      setErrors(errorsList)
    } else {
      setErrors(null)
      setLoading.on()
      const data = {
        name,
        email,
        message: encodeURIComponent(message),
        phone,
        category,
      }
      const doc_id = email.replace('@', '[at]').replace('.', '[dot]')

      MessageService.send(data, doc_id).then((status) => {
        setLoading.off()
        if (status) {
          setName('')
          setEmail('')
          setMessage('')
          setPhone('')
          setCategory('')
          toast({
            position: 'bottom-right',
            duration: 6000,
            isClosable: true,
            render: () => (
              <Box color="white" p={3} bg="black">
                <Heading as={'h6'} size={'s'} mb={0}>
                  Thank you {data.name}
                </Heading>
                <Text as={'small'} fontWeight={'600'}>
                  We'll address your request as soon as possible.
                </Text>
              </Box>
            ),
          })
        } else {
          toast({
            position: 'bottom-right',
            duration: 6000,
            isClosable: true,
            render: () => (
              <Box
                color="white"
                p={3}
                bg="black"
                as={'a'}
                href={`mailto:ecocraftsbbsr@gmail.com?subject=Hello!!&body=${encodeURIComponent(message)}`}
                display={'block'}
              >
                <Heading as={'h6'} size={'s'} mb={0}>
                  Sorry {data.name}
                </Heading>
                <Text as={'small'} fontWeight={'600'}>
                  Please try again, you can always drop us an email.
                </Text>
              </Box>
            ),
          })
        }
      })
    }
    return false
  }

  const [categories, setCategories] = useState([])

  useEffect(() => {
    ProductService.categories().then((data) => {
      setCategories(data)
      setLoading.off()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section id="bulk-order" full sectionProps={{ bg: '#f3f3f3', pb: '30px' }}>
      <VStack
        w={'100%'}
        alignItems={'flex-start'}
        my={{ md: '30px' }}
        as={'form'}
        onSubmit={handleFormSubmit}
        name="drop-message"
        className="container"
      >
        <Heading as={'h4'} my={'30px'} w={'100%'} textAlign={{ sm: 'center', md: 'left' }}>
          Bulk Order
        </Heading>
        <Stack
          w={'100%'}
          spacing={8}
          mb={{ sm: '15px', md: '30px' }}
          direction={{ sm: 'column', md: 'row', base: 'row' }}
          px={{ sm: '40px', md: 0 }}
        >
          <FormControl isRequired isInvalid={errors?.name === 'invalid'}>
            <Input
              variant="flushed"
              name="name"
              value={name}
              required
              placeholder="Your good name"
              onChange={handleNameChange}
              _focus={{ borderBottomColor: 'black', boxShadow: '0 1px 0 black' }}
              _placeholder={{ color: 'gray.800' }}
              borderBottomColor={'#bbbbbb'}
            />
            {errors?.name === 'required' && <FormHelperText>Name cannot be empty.</FormHelperText>}
            {errors?.name === 'invalid' && <FormErrorMessage>Name is invalid.</FormErrorMessage>}
          </FormControl>
          <FormControl isRequired isInvalid={errors?.email === 'invalid'}>
            <Input
              variant="flushed"
              name="email"
              type="email"
              value={email}
              required
              onChange={handleEmailChange}
              placeholder="Your email address"
              _focus={{ borderBottomColor: 'black', boxShadow: '0 1px 0 black' }}
              _placeholder={{ color: 'gray.800' }}
              borderBottomColor={'#bbbbbb'}
            />
            {errors?.email === 'required' && (
              <FormHelperText>Email cannot be empty.</FormHelperText>
            )}
            {errors?.email === 'invalid' && <FormErrorMessage>Email is invalid.</FormErrorMessage>}
          </FormControl>
        </Stack>
        <Stack
          w={'100%'}
          spacing={8}
          mb={{ sm: '15px', md: '30px' }}
          direction={{ sm: 'column', md: 'row', base: 'row' }}
          px={{ sm: '40px', md: 0 }}
        >
          <FormControl isRequired isInvalid={errors?.phone === 'invalid'}>
            <Input
              variant="flushed"
              name="phone"
              type="phone"
              value={phone}
              required
              onChange={handlePhoneChange}
              placeholder="Your phone number"
              _focus={{ borderBottomColor: 'black', boxShadow: '0 1px 0 black' }}
              _placeholder={{ color: 'gray.800' }}
              borderBottomColor={'#bbbbbb'}
            />
            {errors?.phone === 'required' && (
              <FormHelperText>Phone number cannot be empty.</FormHelperText>
            )}
            {errors?.phone === 'invalid' && (
              <FormErrorMessage>Phone number is invalid.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={errors?.message === 'invalid'}>
            <Select
              name="category"
              value={category}
              onChange={handleCategoryChange}
              variant="flushed"
              placeholder="Select product category"
              _focus={{ borderBottomColor: 'black', boxShadow: '0 1px 0 black' }}
              _placeholder={{ color: 'gray.800' }}
              borderBottomColor={'#bbbbbb'}
            >
              {categories.map((category, index) => (
                <option key={`catg-${index}`} value={category.data.slug}>
                  {category.data.name}
                </option>
              ))}
              <option value="others">Others</option>
            </Select>
          </FormControl>
        </Stack>
        <FormControl
          isRequired
          isInvalid={errors?.message === 'invalid'}
          px={{ sm: '40px', md: 0 }}
        >
          <Textarea
            placeholder="Your request details in brief"
            name="message"
            resize={'vertical'}
            variant={'flushed'}
            cols={4}
            value={message}
            onChange={handleMessageChange}
            minHeight={'120px'}
            maxHeight={'320px'}
            _focus={{ borderBottomColor: 'black', boxShadow: '0 1px 0 black' }}
            _placeholder={{ color: 'gray.800' }}
            borderBottomColor={'#bbbbbb'}
          />
          {errors?.message === 'required' && (
            <FormHelperText>Message cannot be empty.</FormHelperText>
          )}
          {errors?.message === 'invalid' && (
            <FormErrorMessage>Message is exceeding length of 2000 characters.</FormErrorMessage>
          )}
        </FormControl>
        <Stack alignItems={{ sm: 'center', md: 'flex-end' }} w={'100%'} mt={'30px'}>
          <Button
            borderRadius={0}
            bg={'transparent'}
            borderWidth={2}
            borderColor={'black'}
            color={'black'}
            p={6}
            _hover={{ bg: 'black', color: 'white' }}
            _focus={{ bg: 'black', color: 'white' }}
            type="submit"
            isLoading={loading}
            loadingText="Sending"
            spinnerPlacement="end"
          >
            Send now
          </Button>
        </Stack>
      </VStack>
    </Section>
  )
}

export default BulkOrder
